<template>
	<div class="rin-premium-tournament-container" v-if="!selectedPremiumTournamentPlan">
		<div class="rin-premium-tournament-header">
			<BaseIcon name="star"></BaseIcon>
			<div class="rin-premium-tournament-supertitle">
				<template v-if="!paidPlan">
					{{ translations['monetization_Tournament_Supertitle1'] }}
				</template>
				<template>
					{{ translations['monetization_Tournament_Supertitle2'] }}
				</template>
			</div>
			<div class="rin-premium-tournament-title" v-if="!paidPlan">
				<BaseTypeLabel type="premium" /> {{ translations['shared_EventType_Tournament'] }}
			</div>
			<div class="rin-premium-tournament-subrtitle" v-if="!paidPlan">
				{{ translations['monetization_Tournament_Subtitle'] }}
			</div>
		</div>
		<div class="rin-premium-tournament-body">
			<ul class="rin-premium-tournament-bulletpoints" v-if="!paidPlan">
				<li>
					<BaseIcon name="check"></BaseIcon>
					<span>{{ translations['monetization_Tournament_Bulletpoint1'] }}</span>
				</li>
				<li>
					<BaseIcon name="check"></BaseIcon>
					<span>{{ translations['monetization_Tournament_Bulletpoint2'] }}</span>
				</li>
				<li>
					<BaseIcon name="check"></BaseIcon>
					<span>{{ translations['monetization_Tournament_Bulletpoint4'] }}</span>
				</li>
				<li>
					<BaseIcon name="check"></BaseIcon>
					<span>{{ translations['monetization_Tournament_Bulletpoint6'] }}</span>
				</li>
				<li>
					<BaseIcon name="check"></BaseIcon>
					<span>{{ translations['monetization_Tournament_Bulletpoint7'] }}</span>
				</li>
			</ul>
			<BaseCard v-else class="rin-premium-tournament-current-plan" type="primary" size="s" :shadow="false">
				<template #title>
					<BaseTypeLabel type="current_plan" />
				</template>
				<template #body>
					<h6>
						{{ translations['monetization_Tournament_UpTo'] }} {{ paidPlan.endTierUnits }}
						{{ translations['monetization_Tournament_Players'] }}
					</h6>
					<span>
						{{ translations['monetization_Tournament_YouPaid'] }} {{ paidPlan.price }}
						{{ nomenclatures.Currencies[paidPlan.currency].name }}</span
					>
				</template>
			</BaseCard>

			<div class="rin-premium-tournament-player-count" v-if="showPlans">
				{{ translations['monetization_Tournament_NumberOfEntries'] }} {{ playerCount }}
			</div>

			<div class="rin-premium-tournament-description" v-if="showPlans">
				<span>{{ translations['monetization_Player_ModalDescription'] }}</span>
			</div>

			<div class="rin-premium-tournament-plans-container" v-if="showPlans">
				<BaseCard
					type="primary"
					size="s"
					:shadow="false"
					v-for="(plan, idx) in plans"
					:key="plan.price"
					:class="{ 'not-eligible': !plan.isEligible }"
				>
					<template #body>
						<div class="rin-premium-tournament-modal-plan-limits">
							<span v-if="plan.endTierUnits">
								{{ translations['monetization_Tournament_UpTo'] }} <br />{{ plan.endTierUnits }}
								{{ translations['monetization_Tournament_Players'] }}
							</span>
							<span v-else>
								{{ translations['monetization_Tournament_Unlimited'] }}
							</span>
						</div>
						<div
							class="rin-premium-tournament-modal-plan-cost"
							v-if="!(paidPlan && paidPlan.endTierUnits == plan.endTierUnits)"
						>
							{{ plan.price }} {{ nomenclatures.Currencies[plan.currency].name }}
						</div>
					</template>
					<template #footer>
						<BaseButton
							type="premium"
							size="s"
							@click.native="actionButton(idx)"
							:disabled="!plan.isEligible"
						>
							<template v-if="layout.player.isAuthenticated">
								{{ translations['monetization_Player_ModalPlanBuyButton'] }}
							</template>
							<template v-else>
								{{ translations['shared_SeeMore'] }}
							</template>
						</BaseButton>
					</template>
				</BaseCard>
			</div>
			<div v-if="showPlans" class="rin-premium-tournament-additional-info">
				<div>
					{{ translations['monetization_Tournament_AdditionalInfo1'] }}
				</div>
				<div>
					{{ translations['monetization_Tournament_AdditionalInfo2'] }}
				</div>
				<div>
					{{ translations['monetization_Tournament_AdditionalInfo3'] }}
				</div>
				<div>
					{{ translations['monetization_Tournament_AdditionalInfo4'] }}
				</div>
			</div>
		</div>
		<div class="rin-premium-tournament-footer">
			<BaseAlert v-if="!showPlans" type="info">
				{{ translations['monetization_Tournament_Footer'] }}
			</BaseAlert>
		</div>
	</div>
	<div v-else>
		<ChooseBillingAddress />
	</div>
</template>

<script>
import BaseAlert from '@/components/rin_new_design/components/base/BaseAlert';
import BaseButton from '@/components/rin_new_design/components/base/BaseButton';
import BaseCard from '@/components/rin_new_design/components/base/BaseCard';
import BaseIcon from '@/components/rin_new_design/components/base/BaseIcon';
import BaseTypeLabel from '@/components/rin_new_design/components/base/BaseTypeLabel';

import ChooseBillingAddress from '@/components/rin_new_design/components/monetization/ChooseBillingAddress';

import { mapGetters, mapMutations } from 'vuex';

export default {
	name: 'PremiumTournament',
	components: {
		BaseAlert,
		BaseButton,
		BaseCard,
		BaseIcon,
		BaseTypeLabel,
		ChooseBillingAddress,
	},
	props: {
		tournamentId: {
			type: [Number, String],
			default: 0,
		},
	},
	computed: {
		...mapGetters(['layout', 'translations', 'nomenclatures']),
		...mapGetters('monetization', ['selectedPremiumTournamentPlan']),
		showPlans() {
			return this.tournamentId > 0;
		},
	},
	data() {
		return {
			plans: [],
			playerCount: 0,
			paidPlan: null,
		};
	},
	methods: {
		...mapMutations('monetization', ['updateSelectedPremiumTournamentPlan']),
		actionButton(idx) {
			if (this.layout.player.isAuthenticated) {
				this.updateSelectedPremiumTournamentPlan({ ...this.plans[idx], tournamentId: +this.tournamentId });
			} else {
				this.$router.push({ name: 'pricing', params: { language: this.layout.language }, hash: '#boxPlayers' });
			}
		},
	},
	mounted() {
		if (this.showPlans) {
			this.$http
				.get(`${this.nomenclatures.ApiDomain}/Monetization/GetTournamentPlansAsync`, {
					params: {
						tournamentId: this.tournamentId,
					},
				})
				.then((res) => {
					this.playerCount = res.data?.playerCount;
					this.plans = res.data?.plans;
					this.paidPlan = res.data?.paidPlan;
				});
		}
	},
	beforeDestroy() {
		this.updateSelectedPremiumTournamentPlan(null);
	},
};
</script>
