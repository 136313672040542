import adslist from '@/app/googleadsmodels';

export default {
    install(Vue, router, store, spaEventBus, http) {

        this.router = router;
        this.store = store;
        this.spaEventBus = spaEventBus;
        this.http = http;

        Vue.prototype.$googleAds = this;
    },
    areGoogleAdsShown: false,
    router: null,
    store: null,
    http: null,
    spaEventBus: null,
    showRealAds: false,


    // Ads manager start
    async tick() {
        if (!window.googletag || !window.googletag?.cmd) return;

        this.showRealAds = this.store.getters.nomenclatures.Environment == 'prod';

        const shouldHideAllAds = await this.shouldHideAllAdsAsync();

        if (shouldHideAllAds) {
            this.areGoogleAdsShown = false;
            this.hideGoogleAds();
            return;
        }

        if (!this.areGoogleAdsShown) {
            this.showGoogleAds();
        }
        else {
            this.refreshGoogleAds();
        }
    },

    hideGoogleAds() {
        if (!this.areGoogleAdsShown) return;
        this.areGoogleAdsShown = false;

        window.googletag.cmd.push(function () {

            window.googletag.destroySlots();
            //window.googletag.pubads().clear();
        });

    },

    showGoogleAds() {

        if (this.areGoogleAdsShown) return;
        this.areGoogleAdsShown = true;

        let typeOfAdsToBeShown = adslist.filter(x => x.isTestAd === !this.showRealAds);

        const isMobile = window.innerWidth < 1024;

        if (isMobile) {
            typeOfAdsToBeShown.filter(x => x.isMobile).forEach(x => x.display());
        }
        else {
            typeOfAdsToBeShown.filter(x => x.isDesktop).forEach(x => x.display());
        }

        window.googletag.cmd.push(function () {
            window.googletag.pubads().enableSingleRequest();
            window.googletag.enableServices();
        })

        console.log('Show Google Ads Called');
    },

    refreshGoogleAds() {
        window.googletag.cmd.push(function () {
            window.googletag.pubads().refresh();
        });
        console.log('Refresh Google Ads Called');

    },

    // Ads manager end

    async shouldHideAllAdsAsync() {
        const isEmbed = this.store.getters.layout.isEmbed;
        const isPro = this.store.getters.layout.player.IsProPlayer;
        const isRouteForbidden = this.isRouteForbidden();

        if (isEmbed || isPro || isRouteForbidden) return true;

        if (this.store.getters.nomenclatures.IsPremiumAllowed && this.isInTournamentFeature()) {
            const isPremiumTournament = await this.isPremiumTournamentAsync();
            return isPremiumTournament;
        }

        if (this.store.getters.nomenclatures.IsPremiumAllowed && this.isInOrganizationFeature()) {
            let isPremiumOrganization = await this.isPremiumOrganisationAsync();
            return isPremiumOrganization;
        }

        return false;
    },

    isInPremiumFeatureRoute() {
        return this.isInTournamentFeature() || this.isInOrganizationFeature();
    },

    isInTournamentFeature() {
        var feature = this.router.currentRoute.meta?.feature;
        if (!feature) return false;
        return 'Tournament' === feature;
    },

    isInOrganizationFeature() {
        var feature = this.router.currentRoute.meta?.feature;
        if (!feature) return false;
        return 'Organisation' === feature;
    },

    async isPremiumTournamentAsync() {        
        return this.http.get(`${this.store.getters.nomenclatures.ApiDomain}/Monetization/GetIsPremiumTournamentAsync`,
            {
                params: { tournamentId: this.store.getters.layout.page.id }
            })
            .then(res => res.data)
            .catch(e => {
                if (e.response && e.response.data.code == this.nomenclatures.PremiumPlanIsNotAllowed) {
                    return false;
                }

                return true;
            });
    },

    async isPremiumOrganisationAsync() {

        const organisationId = this.router.currentRoute.params.id;

        return this.http.get(`${this.store.getters.nomenclatures.ApiDomain}/Monetization/GetIsPremiumOrganisationAsync`,
            {
                params: { organisationId: organisationId }
            })
            .then(res => res.data)
            .catch(() => false);
    },

    isRouteForbidden() {
        return !!this.router.currentRoute?.meta?.ads?.isForbidden;
    },

    async generateAd() {              
        const hideAds = await this.shouldHideAllAdsAsync();

        if (hideAds) {
            this.spaEventBus.$emit('showGoogleAd', false);
        }
        else {
            this.spaEventBus.$emit('showGoogleAd', true);
        }
    },
    emitRefreshGoogleAd() {
        this.spaEventBus.$emit('refreshGoogleAd');
    }



}