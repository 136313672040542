export default {
    namespaced: true,
    state: {
        shown: false,
        closeButton: false,
        okButton: false,
        okText: '',
        title: '',
        body: '',
        bodyhtml: '',
        successCb: null,
        closeCb: null
    },
    mutations: {
        alert(state, payload) {
            state.shown = true;
            state.closeButton = true;
            state.okButton = false;
            state.okText = '';

            state.title = payload.title;
            state.body = payload.body;
            state.bodyhtml = payload.bodyhtml;
        },
        confirm(state, payload) {
            state.shown = true;
            state.closeButton = true;
            state.okButton = true;
            state.title = payload.title;
            state.body = payload.body;
            state.okText = payload.successText;
            state.successCb = payload.successCb;
            state.closeCb = payload.closeCb;
        },
        close(state) {
            state.shown = false;
            state.closeButton = false;
            state.okButton = false;
            state.title = '';
            state.body = '';
            state.okText = '';
            state.successCb = null;
            state.closeCb = null;
        }
    },
    actions: {
        show({ commit }, payload) {
            if (payload.alert) {
                commit("alert", payload);
            } else if (payload.confirm) {
                commit('confirm', payload);
            }
        },
        close({ commit }) {
            commit('close')
        }
    },
    getters: {
        isShown(state) {
            return state.shown;
        },
        title(state) {
            return state.title;
        },
        body(state) {
            return state.body;
        },
        bodyhtml(state) {
            return state.bodyhtml;
        },
        hasSuccessButton(state) {
            return state.okButton;
        },
        closeCb(state) {
            return state.closeCb;
        },
        successCb(state) {
            return state.successCb;
        },
        successText(state) {
            return state.okText;
        }
    }
};
