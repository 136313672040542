<template>
    <div class="google-ad-bottom-banner">

        <template v-if="isProd">

            <!-- Rankedin Banner Ad Unit -->
            <HideAdsBtn class="hide-ads" />
            <p class="advertisement-info">{{ translations['shared_Ads_Label'] }}</p>
            <ins class="adsbygoogle"
                 style="display:inline-block;width:100%;height:50px"
                 data-ad-client="ca-pub-2431348456451065"
                 data-ad-slot="1706358634"
                 data-full-width-responsive="true"></ins>

        </template>
        <template v-else>
            <HideAdsBtn class="hide-ads" />
            <p class="advertisement-info">{{ translations['shared_Ads_Label'] }}</p>
            <img style="height: 50px; width:350px; display: block; margin: auto" src="https://cdn.rankedin.com/images/static/test-banner_320x50.png" />
        </template>

    </div>
</template>
<style>
    .google-ad-bottom-banner {
        width: 100%;
        background-color: white;
    }
    .advertisement-info {
        margin-bottom: 2px;
        font-size: 12px;
        color: grey;
        text-align: right;
        margin-right: 10px;
    }

    .hide-ads {
        position: absolute;
    }
</style>
<script>
    import { mapGetters } from 'vuex';
    import HideAdsBtn from '@/components/rin_new_design/components/GoogleAds/HideAdsButton.vue';

    export default {
        name: 'BottomBanner',
        components: {
            HideAdsBtn
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),

            isProd() {
                return this.nomenclatures.Environment == 'prod';
            }
        },
        mounted() {
            if (this.isProd) {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            }
        }
    }
</script>