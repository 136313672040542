<template>
    <div class="modal fade" tabindex="-1" role="dialog" id="modal-enter-results">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <p>
                        <strong>{{translations["shared_MatchesListPartial_EnterResults"]}}</strong>
                        <span class="sport-icon" v-bind:class="sportCSSClass + ' fa-2x'">
                        </span>
                    </p>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" class="icon icon-plus"></span>
                    </button>
                </div>
                <div class="modal-body" v-if="!isLoading">
                    <form id="modal-form-enter-result">

                        <div class="row jcb aie">
                            <div class="col col-5 form-group text-center">
                                <label for="player1">{{participant1Name}} {{firstPositionWinState}}</label>
                                <span v-if="isForDouble"><br /></span>
                                <span id="show-chosen-double"></span>
                                <span id="double-partner-btn" class="hidden" style="color:#009fdd">
                                    <span class="fa fa-plus-circle "></span>
                                </span>
                                <input type="text" id="double-partner" hidden style="max-width:62%" />
                                <input v-show="!isCancellationType" v-bind:class="{ 'required' :  validation.gamesAndSetsMismatch || (!isValidSetGameFields && validation.participantScoresChanged) }" v-model="firstPlayerScore" id="firstPlayerScore" type="number" min="0" max="5" oninput="validity.valid||(value='');">
                            </div>

                            <div class="dropdown col col-2 text-center opt opt-result">
                                <a v-show="isTournament || isTeamLeague" class="" href="#" role="button" id="result-option" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{resultOptionText}} <div class="icon icon-v small"></div>
                                </a>
                                <div class="dropdown-menu optMenu" aria-labelledby="result-option">
                                    <a v-bind:class="{'active': cancellationTypeId === 2}" href="#" v-on:click="setCancellation(2)" data-toggle="collapse">W.O</a>
                                    <a v-bind:class="{'active': cancellationTypeId === 4}" href="#" v-on:click="setCancellation(4)" data-toggle="collapse">Ret.</a>
                                    <a v-bind:class="{'active': cancellationTypeId === 3}" href="#" v-on:click="setCancellation(3)" data-toggle="collapse">Dis.</a>
                                </div>
                            </div>

                            <div class="col col-5 form-group text-center">
                                <label for="player2">{{participant2Name}} {{secondPositionWinState}}</label>
                                <input class="player" v-if="!isCancellationType" v-bind:class="{ 'required' :  validation.gamesAndSetsMismatch || (!isValidSetGameFields && validation.participantScoresChanged) }" v-model="secondPlayerScore" id="secondPlayerScore" type="number" min="0" max="5" oninput="validity.valid||(value='');">
                            </div>
                            <div class="col col-12 form-check detailedScoring" v-show="!isCancellationType">
                                <input v-on:click="toggleExtendedScoring()" v-model="shouldShow.showExtendedScores" class="form-check-input" type="checkbox" id="detailedScoring" href="javascript:;" role="button">
                                <label class="form-check-label" for="detailedScoring">{{translations["pLayer_PLayerEventsPartial_ExtendedScore"]}}:</label>
                            </div>
                        </div>
                        <div class="row collapse" id="detailedScoringCollapse" v-bind:class="{'show':!isCancellationType && shouldShow.showExtendedScores}">
                            <div class="col col-12 col-md-5 p-0">
                                <div class="row">
                                    <div class="col col-12 text-center">
                                        <label for="lorem3">Sets</label>
                                    </div>

                                    <div class="row" v-for="(score, index) in extendedScores">
                                        <div class="col col-6">
                                            <input type="number" class="gameResult" min="0" value="0" oninput="validity.valid||(value='');" v-model="score[0]" v-bind:class="{ 'required' : score[0] == score[1] && validation.resultSubmitClicked}">
                                        </div>
                                        <div class="col col-6">
                                            <input type="number" class="gameResult" min="0" value="0" oninput="validity.valid||(value='');" v-model="score[1]" v-bind:class="{ 'required' : score[0] == score[1] && validation.resultSubmitClicked}">
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col col-12 col-md-4" v-show="hasTiebreaks">
                                <div class="row">
                                    <div class="col col-12 text-center">
                                        <label for="lorem3">Tiebreak</label>
                                    </div>
                                    <div class="row" v-for="(score, index) in extendedScores">
                                        <div class="col col-6">
                                            <input type="number" class="gameResult tiebreaks" min="0" oninput="validity.valid||(value='');" v-model="score[3]">
                                        </div>
                                        <div class="col col-6">
                                            <input type="number" class="gameResult tiebreaks" min="0" oninput="validity.valid||(value='');" v-model="score[4]">
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col col-12 col-md-3">
                                <div class="row">
                                    <div class="col col-12 text-center">
                                        <label for="lorem3">{{translations["shared_T15"]}} (min)</label>
                                    </div>
                                    <div class="col col-12" v-for="(score, index) in extendedScores">
                                        <input type="number" class="gameResult setDuration" value="0" min="0" oninput="validity.valid||(value='');" v-model="score[2]">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row collapse" id="player" v-bind:class="{'show':isCancellationType}">
                            <div class="col col-12 p-0">
                                <div class="row">
                                    <div class="col col-12">
                                        <label for="player01">{{translations["shared_T16"]}}:</label>
                                    </div>
                                    <div class="col col-12 form-radio">
                                        <input class="form-radio-input" type="radio" value="1" id="player01" name="player" v-model="winnerPosition">
                                        <label class="form-radio-label" for="player01">{{participant1Name}}</label>
                                    </div>
                                    <div class="col col-12 form-radio">
                                        <input class="form-radio-input" type="radio" value="2" id="player02" name="player" v-model="winnerPosition">
                                        <label class="form-radio-label" for="player02">{{participant2Name}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 1.5rem; margin-bottom: .5rem;">
                            <div class="col-12 p-0">
                                <div class="row aic">
                                    <div class="col col-auto">
                                        <a href="#" data-dismiss="modal" class="btn btn-sm">{{translations["clubLeagueSettingsPartial_Cancel"]}}</a>
                                    </div>
                                    <div class="col col-auto">
                                        <input type="button" id="submitResults" v-on:click="submitResults()" class="text-white btn btn-info btn-sm width-auto" value="Submit" />
                                    </div>
                                    <div class="col text-right">
                                        <a href="#confirmBox" class="btn btn-sm" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="confirmBox">{{translations["shared_Matches_ResetResult_Action"]}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <!--<div class="modal-footer">
                </div>-->
                <div class="confirmBox collapse" id="confirmBox">
                    <div class="row nowrap jcb">
                        <div class="col">{{translations["shared_T17"]}}?</div>
                        <div class="col-auto">
                            <a href="#" class="cancelConfirm btn btn-sm">{{translations["clubLeagueSettingsPartial_Cancel"]}}</a>
                            <a href="#" class="clearConfirm btn btn-sm btn-info" v-on:click="resetResultConfirmed()">{{translations["tournamentClasses_Clear"]}}</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
    import bootbox from 'bootbox';
    import spaEventBus from '@/app/eventBus';

    import { mapGetters } from 'vuex';

    export default {
        name: 'modal-enter-results',
        data: function () {
            return {
                isLoading: false,
                cancellationTypeId: null,
                winnerPosition: null,
                participant1Name: '',
                participant2Name: '',
                showCancellationOptions: false,
                isCancellation: false,
                firstPlayerScore: 0,
                secondPlayerScore: 0,
                firstPlayerExtendedScore: '',
                secondPlayerExtendedScore: '',
                participant2ID: 0,
                participant1ID: 0,
                duration: 0,
                durationExtendedScore: '',
                sport: null,
                sportCSSClass: '',
                currentPlayerId: 0,
                challengeId: 0,
                eventType: 0,
                rankingId: 0,
                rankingName: '',
                tiebreaks: [],
                isTournament: false,
                isTeamLeague: false,
                extendedScores: [],
                isForDouble: false,
                participant1CancellationType: 0,
                participant2CancellationType: 0,
                isRankingSelected: false,
                matchDuration: 0,
                shouldShow: {
                    showSubRankingSection: false,
                    showSubranking: false,
                    showExtendedScores: false,
                },
                eventTypes: {
                    clubLeague: 2,
                    tournament: 4,
                    teamLeague: 3
                },
                canDecreaseExtendedScores: true,
                sportsEnum: { none: null, squash: 1, badminton: 2, tableTennis: 3, tennis: 4, padel: 5, racquetball: 6, soccerSquash: 7, fronton: 8, teqball: 9, crossminton: 10, pickleball: 11, roundnet: 12, bowls: 13, footballtennis: 14 },
                rankingSearchTerm: '',
                rankingSearchResults: [],
                validation: {
                    participantScoresChanged: false,
                    resultSubmitClicked: false,
                    gamesAndSetsMismatch: false
                },
                isFromSpa: false
            }
        },
        watch: {
            firstPlayerScore: function () {
                this.validation.participantScoresChanged = true;
                this.validation.gamesAndSetsMismatch = false;
                this.changeExtendedScoreDetailsPanel();
            },
            secondPlayerScore: function () {
                this.validation.participantScoresChanged = true;
                this.validation.gamesAndSetsMismatch = false;
                this.changeExtendedScoreDetailsPanel();
            }
        },
        mounted() {

            let _this = this;

            spaEventBus.$on('enteringResults_enteringResults', (payload) => {
                _this.isFromSpa = true;
                _this.enteringResults(payload.challengeId, payload.isFromSpa, payload.cancelationPreselect);
            });

            spaEventBus.$on('enteringResults_resetResults', (payload) => {
                _this.isFromSpa = true;
                _this.challengeId = payload.challengeId;
                _this.resetResult();
            });

            spaEventBus.$on('enteringResults_matchNotPlayed', (payload) => {
                _this.isFromSpa = true;
                _this.matchNotPlayed(payload.challengeId);
            });

            // modal cancel
            $('.cancelConfirm, .clearConfirm').on('click', function () {
                var t = $(this);
                t.closest('.collapse').collapse('hide');
            });
            // modal cancel
        },
        computed: {
            ...mapGetters(['translations', 'nomenclatures']),

            resetModel: function () {
                let results = {
                    challengeId: this.challengeId,
                    participant1CancellationType: 0,
                    participant2CancellationType: 0,
                    reset: true,
                    participant1Score: 0,
                    participant2Score: 0,
                    participant1Games: '',
                    participant2Games: '',
                    setDuration: '',
                    duration: false,
                    games: false,
                    currPlayerId: this.currentPlayerId
                };

                return results;
            },
            submitResultModel: function () {
                let results = {
                    challengeId: this.challengeId,
                    participant1CancellationType: this.participant1CancellationType,
                    participant2CancellationType: this.participant2CancellationType,
                    reset: false,
                    participant1Score: this.firstPlayerScore,
                    participant2Score: this.secondPlayerScore,
                    participant1Games: this.firstPlayerExtendedScore,
                    participant2Games: this.secondPlayerExtendedScore,
                    setDurations: this.durationExtendedScore,
                    participant1ID: this.participant1ID,
                    participant2ID: this.participant2ID,
                    duration: false,
                    games: false,
                    matchDuration: 0,
                    tiebreaks: this.tiebreaks,
                    currPlayerId: this.currentPlayerId
                };

                return results;
            },
            newChallengeResultModel: function () {
                const results = {
                    challengeId: this.challengeId,
                    participant1CancellationType: this.participant1CancellationType,
                    participant2CancellationType: this.participant2CancellationType,
                    reset: false,
                    participant1Score: this.firstPlayerScore,
                    participant2Score: this.secondPlayerScore,
                    participant1Games: this.firstPlayerExtendedScore,
                    participant2Games: this.secondPlayerExtendedScore,
                    setDurations: this.durationExtendedScore,
                    duration: false,
                    games: false,
                    matchDuration: 0,
                    tiebreaks: this.tiebreaks,
                    currPlayerId: this.currentPlayerId,
                    participant2ID: this.participant2ID,
                    participant1ID: this.currentplayerId
                };

                return results;
            },
            isCancellationType: function () {
                return this.cancellationTypeId !== null;
            },
            firstPositionWinState: function () {
                if (this.winnerPosition == 1) return '(Win)';
                if (this.winnerPosition == 2) return '(Lose)';
            },
            secondPositionWinState: function () {
                if (this.winnerPosition == 2) return '(Win)';
                if (this.winnerPosition == 1) return '(Lose)';
            },
            resultOptionText: function () {
                if (this.cancellationTypeId == 2) return 'W.O.';
                if (this.cancellationTypeId == 4) return 'Ret.';
                if (this.cancellationTypeId == 3) return 'Dis.';
                return "";
            },
            hasTiebreaks: function () {
                return this.sport === this.sportsEnum.tennis || this.sport === this.sportsEnum.padel;
            },
            isValidSetGameFields: function () {
                const _this = this;

                if (this.validation.gamesAndSetsMismatch) return false;

                if (_this.firstPlayerScore < 0 ||
                    _this.firstPlayerScore > 99 ||
                    typeof (_this.firstPlayerScore) == 'undefined' ||
                    _this.firstPlayerScore === "") {
                    return false;
                }

                if (_this.secondPlayerScore < 0 ||
                    _this.secondPlayerScore > 99 ||
                    typeof (_this.secondPlayerScore) == 'undefined' ||
                    _this.secondPlayerScore === "") {
                    return false;
                }

                if (_this.firstPlayerScore === _this.secondPlayerScore && _this.cancellationTypeId === null) {
                    return false;
                }

                if (_this.winnerPosition == 2) {
                    _this.participant1CancellationType = _this.cancellationTypeId;
                    _this.participant2CancellationType = 0;
                }
                else if (_this.winnerPosition == 1) {
                    _this.participant1CancellationType = 0;
                    _this.participant2CancellationType = _this.cancellationTypeId;
                }

                if (_this.firstPlayerScore === _this.secondPlayerScore && !(_this.participant1CancellationType > 0 || _this.participant2CancellationType > 0)) {
                    return false;
                }


                return true;
            },
        },
        methods: {
            setWinner: function (position) {
                this.winnerPosition = position;
            },
            clearResults: function () {
                this.clearCancellation();
                this.firstPlayerScore = 0;
                this.secondPlayerScore = 0;
                this.shouldShow.showExtendedScores = false;
                this.extendedScores = [];
                this.participant1Name = '';
                this.participant2Name = '';
                this.shouldShow.showSubRankingSection = false;
                this.firstPlayerExtendedScore = '';
                this.secondPlayerExtendedScore = '';
                this.durationExtendedScore = '';
            },
            clearCancellation: function () {
                this.cancellationTypeId = null;
                this.winnerPosition = null;
                this.participant1CancellationType = 0;
                this.participant2CancellationType = 0;
            },
            toggleExtendedScoring: function () {
                this.shouldShow.showExtendedScores = !this.shouldShow.showExtendedScores;
                this.changeExtendedScoreDetailsPanel();
            },
            getEmptyExtendedScores() {
                if (this.hasTiebreaks) {
                    return [0, 0, 0, '', ''];
                } else {
                    return [0, 0, 0];
                }
            },
            setCancellation(cancellationType) {
                if (cancellationType == null || typeof cancellationType === 'undefined') {
                    $("#player").collapse('hide');
                    this.cancellationTypeId = null;
                }
                else {
                    $("#player").collapse('show');
                    this.cancellationTypeId = cancellationType;
                }
            },
            setCancellationType: function (participant1CancellationType, participant2CancellationType) {
                if (participant2CancellationType > 0) {
                    this.participant2CancellationType = participant2CancellationType;
                    this.cancellationTypeId = participant2CancellationType;
                    this.winnerPosition = 1;
                } else if (participant1CancellationType > 0) {
                    this.participant1CancellationType = participant1CancellationType;
                    this.cancellationTypeId = participant1CancellationType;
                    this.winnerPosition = 2;
                }
            },
            modalResultsPreview: function (response, challengeId) {
                const _this = this;
                _this.isLoading = false;
                let extendedScores = [];
                _this.challengeId = challengeId;
                _this.firstPlayerScore = response.Participant1Score;
                _this.secondPlayerScore = response.Participant2Score;
                _this.shouldShow.showExtendedScores = response.IsDetailedScoring;
                _this.isCancellation = response.IsCancelation;
                _this.sport = response.Sport;
                _this.sportCSSClass = response.SportCSSClass;
                _this.participant1Name = response.Participant1Name;
                _this.participant2Name = response.Participant2Name;
                _this.duration = response.MatchDuration || response.GetSetDurations.reduce((a, b) => a + b, 0);
                _this.eventType = response.EventType;
                _this.rankingId = response.RankingId;
                _this.tiebreaks = response.Tiebreaks;
                _this.currentplayerId = response.CurrentPlayerId;
                _this.showCancellationOptions = response.EventType === 4;
                _this.isTournament = response.EventType === 4;
                _this.isTeamLeague = response.EventType === 3;
                _this.participant1ID = response.Participant1ID;
                _this.participant2ID = response.Participant2ID;
                //vue
                _this.canDecreaseExtendedScores = false;

                Vue.nextTick(function () {
                    _this.canDecreaseExtendedScores = true;
                });

                if (response.Participant1CancellationType > 0) {
                    _this.setCancellationType(response.Participant1CancellationType, response.Participant2CancellationType);
                    _this.setWinner(2);
                }

                if (response.Participant2CancellationType > 0) {
                    _this.setCancellationType(response.Participant1CancellationType, response.Participant2CancellationType);
                    _this.setWinner(1);
                }

                if (response.FirstPlayerGames !== null && typeof (response.FirstPlayerGames) !== 'undefined' && response.FirstPlayerGames.length > 0) {
                    let participant1Games = response.FirstPlayerGames;
                    let participant2Games = response.SecondPlayerGames;
                    let setsDurations = response.GetSetDurations;
                    let tiebreaks = response.Tiebreaks;
                    for (let i = 0; i < participant1Games.length; i++) {
                        if (_this.hasTiebreaks) {
                            extendedScores[i] = [participant1Games[i], participant2Games[i], setsDurations[i], tiebreaks[i].First, tiebreaks[i].Second];
                        } else {
                            extendedScores[i] = [participant1Games[i], participant2Games[i], setsDurations[i]];
                        }
                    }
                }
                else {
                    for (let j = 0; j < _this.firstPlayerScore + _this.secondPlayerScore; j++) {
                        extendedScores[j] = _this.getEmptyExtendedScores();
                    }
                }

                _this.extendedScores = extendedScores;

            },
            validateResultScores: function (score1, score2, cancellationEvent) {
                if (score1 == score2 && !(cancellationEvent | false)) {
                    return false;
                }
                else if (score1 < 0 && score2 < 0) {
                    return false;
                }
                else if (score1 < 0) {
                    return false;
                }
                else if (score2 < 0) {
                    return false;
                }
                else {
                    return true;
                }
            },
            resetResult: function () {
                const _this = this;
                bootbox.confirm('Are you sure you want to clear result for this match?', result => {
                    if (result) {
                        _this.resetResultConfirmed();
                    }
                });
            },

            resetResultConfirmed: function () {
                const _this = this;

                _this.isLoading = true;
                _this.hideSections();
                _this.$http({
                    method: 'post',
                    url: `${this.nomenclatures.ApiDomain}/Match/SubmitResultAsync`,
                    data: _this.resetModel
                }).then(function () {
                    $("#modal-enter-results").modal("hide");
                    _this.isLoading = false;

                    spaEventBus.$emit('reloadMatches');
                }).catch(function (error) {
                    $("#modal-enter-results").modal("hide");
                    bootbox.alert(error.response.statusText);
                    _this.isLoading = false;
                });
            },

            changeExtendedScoreDetailsPanel: function () {
                const _this = this;
                if (!_this.isValidSetGameFields) {
                    return false;
                }

                let extendedScores = _this.extendedScores;
                let extendedScoresCount = parseInt(_this.firstPlayerScore) + parseInt(_this.secondPlayerScore);

                for (let i = 0; i < extendedScoresCount; i++) {
                    if (extendedScores[i]) {
                        continue;
                    }
                    else {
                        extendedScores[i] = _this.getEmptyExtendedScores();
                    }
                }

                if (_this.canDecreaseExtendedScores) {
                    extendedScores = extendedScores.slice(0, extendedScoresCount);
                }

                _this.extendedScores = extendedScores;
            },

            gameResultOnInput: function () {
                this.changeExtendedScoreDetailsPanel();
            },
            submitResults: function () {
                const _this = this;
                _this.validation.resultSubmitClicked = true;
                _this.validation.gamesAndSetsMismatch = false;
                if (!_this.isValidSetGameFields && !_this.isCancellationType) {
                    _this.validation.gamesAndSetsMismatch = true;
                    return false;
                }
                let extendedScoring = _this.shouldShow.showExtendedScores;
                if (extendedScoring && !_this.isCancellationType) {

                    let extendedScores = _this.extendedScores
                        .filter(scoreEntry => _.some(scoreEntry, entry => Number(entry) > 0));

                    let firstPlayerExtendedScoreArray = extendedScores.map(function (scoreEntry) { return parseInt(scoreEntry[0]) });
                    let secondPlayerExtendedScoreArray = extendedScores.map(function (scoreEntry) { return parseInt(scoreEntry[1]) });
                    let durationExtendedScoreArray = extendedScores.map(function (scoreEntry) { return Number(scoreEntry[2]) });

                    let tiebreaks = null;
                    if (_this.hasTiebreaks) {
                        tiebreaks = extendedScores.map(function (scoreEntry) {
                            return { first: Number(scoreEntry[3]), second: Number(scoreEntry[4]) };
                        });
                        _this.tiebreaks = tiebreaks;
                    }
                    let firstGamesValidation = 0;
                    let secondGamesValidation = 0;
                    let firstPlayerGamesTemp = '';
                    let secondPlayerGamesTemp = '';
                    let setDurationsTemp = '';
                    for (let i = 0; i < firstPlayerExtendedScoreArray.length; i++) {
                        if (firstPlayerExtendedScoreArray[i] > secondPlayerExtendedScoreArray[i]) {
                            firstGamesValidation++;
                        }
                        else {
                            secondGamesValidation++;
                        }
                        firstPlayerGamesTemp += firstPlayerExtendedScoreArray[i] + ',';
                        secondPlayerGamesTemp += secondPlayerExtendedScoreArray[i] + ',';
                        setDurationsTemp += durationExtendedScoreArray[i] + ',';
                    }
                    _this.firstPlayerExtendedScore = firstPlayerGamesTemp;
                    _this.secondPlayerExtendedScore = secondPlayerGamesTemp;
                    _this.durationExtendedScore = setDurationsTemp;

                    if (parseInt(_this.firstPlayerScore) !== firstGamesValidation && !_this.isCancellationType) {
                        _this.validation.gamesAndSetsMismatch = true;
                        return false;
                    }
                    if (parseInt(_this.secondPlayerScore) !== secondGamesValidation && !_this.isCancellationType) {
                        _this.validation.gamesAndSetsMismatch = true;
                        return false;
                    }
                }
                else {
                    _this.extendedScores = [];
                    _this.firstPlayerExtendedScore = '';
                    _this.secondPlayerExtendedScore = '';
                    console.log(_this.submitResultModel);
                }

                if (!_this.validateResultScores(_this.firstPlayerScore, _this.secondPlayerScore, _this.participant1CancellationType > 0 || _this.participant2CancellationType > 0)) {
                    _this.validation.gamesAndSetsMismatch = true;
                    return false;
                }

                const result = _this.submitResultModel;

                _this.hideSections();

                _this.submitMatchResult(result);
            },
            submitMatchResult(result) {
                const _this = this;
                _this.isLoading = true;
                _this.$http({
                    method: 'post',
                    url: `${this.nomenclatures.ApiDomain}/Match/SubmitResultAsync`,
                    data: result
                }).then(function () {
                    _this.isLoading = false;
                    $("#modal-enter-results").modal("hide");
                    _this.clearResults();

                    spaEventBus.$emit('reloadMatches');
                }).catch(function (error) {
                    $("#modal-enter-results").modal("hide");
                    bootbox.alert(error.response.statusText);
                    _this.isLoading = false;
                });
            },
            subRankingCheckBoxClick: function () {
                if (!this.shouldShow.showSubranking && this.isRankingSelected) {
                    this.rankingId = 0;
                    this.rankingName = '';
                    this.isRankingSelected = false;
                }
                this.shouldShow.showSubranking = !this.shouldShow.showSubranking;
            },
            closeRankingSection: function () {
                this.shouldShow.showSubranking = false;
            },
            selectRanking: function (rankingId, rankingName) {
                this.rankingId = rankingId;
                this.rankingName = rankingName;
                this.isRankingSelected = true;
                this.shouldShow.showSubranking = !this.shouldShow.showSubranking;
            },
            subRankingSearchBoxKeyup: function () {
                const _this = this;
                _this.$http({
                    method: 'get',
                    url: '/api/RankingApi/GetByNameWithActive?name=' + _this.rankingSearchTerm
                }).then(function (response) {
                    _this.rankingSearchResults = response.data.splice(0, 5);
                }).catch(function (error) {
                    bootbox.alert(error.message);
                });
            },

            hideSections: function () {
                this.shouldShow.showSubRankingSection = false;
                this.shouldShow.showSubranking = false;
                this.shouldShow.showExtendedScores = false;
            },


            enteringResults: function enteringResults(challengeId, isFromSpa, cancellation) {
                this.clearResults();
                this.isLoading = true;
                this.isFromSpa = isFromSpa;
                this.openModal();
                let _this = this;
                _this.setCancellation(cancellation);

                this.$http({
                    method: 'get',
                    url: `${this.nomenclatures.ApiDomain}/Match/GetChallengeResultAsync?challengeId=` + challengeId
                }).then(function (response) {
                    _this.modalResultsPreview(response.data, challengeId);
                }).catch(function (error) {
                    bootbox.alert(error.message);
                });
            },

            matchNotPlayed: function (challengeId) {
                const notPlayedMatchCancellation = 5;

                this.clearResults();
                this.challengeId = challengeId;
                this.participant1CancellationType = notPlayedMatchCancellation;
                this.participant2CancellationType = notPlayedMatchCancellation;

                const result = this.submitResultModel;

                this.submitMatchResult(result);
            },

            openModal: function openModal() {
                $("#modal-enter-results").modal();
            }

        }
    }


</script>

<style>
    /*this is to prevent scrolling to top when modal opens*/
    body.modal-open {
        overflow: visible;
    }
</style>