<template>
    <div class="google-ad-top-banner">

        <template v-if="isProd">
            <HideAdsBtn class="hide-ads" />
            <p class="advertisement-info"> {{ translations['shared_Ads_Label'] }}</p>
            <!-- Rankedin Banner Ad Unit -->
            <ins class="adsbygoogle"
                 style="display:inline-block;width:980px;height:120px"
                 data-ad-client="ca-pub-2431348456451065"
                 data-ad-slot="8583451076"></ins>

        </template>
        <template v-else>
            <HideAdsBtn class="hide-ads" />
            <p class="advertisement-info">{{ translations['shared_Ads_Label'] }}</p>
            <img style="height: 120px; width:980px; display: block; margin: auto" src="https://cdn.rankedin.com/images/static/test-banner_320x50.png" />
        </template>

    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import HideAdsBtn from '@/components/rin_new_design/components/GoogleAds/HideAdsButton.vue';


    export default {
        name: 'BottomBanner',
        components: {
            HideAdsBtn
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),

            isProd() {
                return this.nomenclatures.Environment == 'prod';
            }
        },
        mounted() {
            if (this.isProd) {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            }
        }
    }
</script>