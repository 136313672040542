export const INIT = 'init';

export const SET_TEAM_MODEL = 'setTeamModel';
export const SET_HEADER_MODEL = 'setHeaderModel';

export const SET_IS_LOADING_MODEL = 'setIsLoadingModel';
export const SET_IS_LOADED_MODEL = 'setIsLoadedModel';
export const GET_TEAM_MODEL_ASYNC = 'getTeamModel';
export const SET_IS_LOADING_MATCHES = 'setIsLoadingMatches';
export const SET_IS_LOADED_MATCHES = 'setIsLoadedMatches';
export const GET_TEAM_MATCHES_ASYNC = 'getTeamMatches';

export default {
    namespaced: true,
    state() {
        return {
            team: {},
            header_model: {},
            team_id: 0,
            tournament_id: 0,
            tournament_class_id: 0,
            is_loading_model: false,
            is_loaded_model: false,

            is_loading_matches: false,
            is_loaded_matches: false,
            repositories: null,
            language: 'en'
        };
    },
    getters: {
        team: state =>
            state.team,
        teamId: state =>
            state.team_id,
        headerModel: state =>
            state.header_model,

        isLoadingModel: state =>
            state.is_loading_model,

        isLoadedModel: state =>
            state.is_loaded_model,

        isLoadingMatches: state =>
            state.is_loading_matches,

        isLoadedMatches: state =>
            state.is_loaded_matches
    },
    mutations: {
        [INIT]: (state, config) => {
            state.team_id = config.teamId;
            state.tournament_id = config.tournamentId;
            state.tournament_class_id = config.tournamentClassId;
            state.repositories = config.repositories;
        },
        [SET_TEAM_MODEL]: (state, payload) => {
            state.team = payload;
        },
        [SET_IS_LOADING_MODEL]: (state, payload) => {
            state.is_loading_model = payload;
        },
        [SET_IS_LOADED_MODEL]: (state, payload) => {
            state.is_loaded_model = payload;
        },
        [SET_IS_LOADING_MATCHES]: (state, payload) => {
            state.is_loading_matches = payload;
        },
        [SET_IS_LOADED_MATCHES]: (state, payload) => {
            state.is_loaded_matches = payload;
        },
        [SET_HEADER_MODEL]: (state, payload) => {
            state.header_model.tournament = payload.Tournament;
            state.header_model.tournamentClass = payload.TournamentClass;
        }
    },
    actions: {
        [GET_TEAM_MODEL_ASYNC]: ({ state, commit }) => {
            commit(SET_IS_LOADED_MODEL, false);
            commit(SET_IS_LOADING_MODEL, true);

            const teamsRepository = state.repositories.teams;
            const teamPromise = teamsRepository.getTeamHomepageAsync(state.tournament_id, state.team_id, state.language)
                .then(response => {
                    commit(SET_TEAM_MODEL, response);
                });

            const tournamentPromise = teamsRepository.getTeamParticipatedTournamentAsync(state.tournament_id, state.tournament_class_id, state.language)
                .then(response => {
                    commit(SET_HEADER_MODEL, response);
                });

            Promise.all([teamPromise, tournamentPromise])
                .then(response => {
                    commit(SET_IS_LOADED_MODEL, true);
                })
                .catch(error => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    commit(SET_IS_LOADING_MODEL, false);
                });
        },

        [GET_TEAM_MATCHES_ASYNC]: ({ state, commit }) => {
            commit(SET_IS_LOADED_MATCHES, false);
            commit(SET_IS_LOADING_MATCHES, true);

            return state.repositories.teams.getTournamentTeamMatchesAsync(state.tournament_id, state.team_id)
                .then(response => {
                    commit(SET_IS_LOADED_MATCHES, true);
                    return response;
                })
                .catch(error => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    commit(SET_IS_LOADING_MATCHES, false);
                });
        }
    }
};