<template>
	<div class="rin-pwa-campaign-container">
		<BaseModalWrapper
			size="prompt"
			:show="showAndroidPwaPopup"
			@close="showAndroidPwaPopup = false & $emit('close-pwa-modal')"
			class="rin-android-pwa-popup"
		>
			<template #body>
				<h3>{{ translations['campaign_Pwa_Title'] }}</h3>
				<div class="rin-pwa-body">
                    <div class="rin-pwa-description">
                        <img src="https://cdn.rankedin.com/images/static/rankedin_app_incon.svg?1" alt="Rankedin">
                        <div>
                            <h4>Rankedin</h4>
                            <h6>rankedin.app</h6>
                        </div>
                    </div>

					<BaseButton type="success" size="s" @click.native="installPwa">
						{{ translations['campaign_Pwa_Install'] }}
					</BaseButton>
				</div>
				<div class="rin-pwa-dont-show-again" @click="dontShowAgain">
					{{ translations['campaign_Pwa_Decline'] }}
				</div>
			</template>
		</BaseModalWrapper>

		<BaseModalWrapper
			size="prompt-bottom"
			:show="showIosChangeBrowser"
			@close="showIosChangeBrowser = false & $emit('close-pwa-modal')"
			class="rin-ios-change-browser-popup"
		>
			<template #body>
				<div class="rin-pwa-header">
					<img src="https://cdn.rankedin.com/images/static/rankedin_app_incon.svg" alt="Rankedin">
					<h3>{{ translations['campaign_Pwa_Title'] }}</h3>
				</div>
				<div class="rin-pwa-body">
					<p>{{ translations['campaign_Pwa_Steps_Title'] }}</p>
					<div>{{ translations['campaign_Pwa_Steps_Step_1'] }} <span> Safari</span></div>
					<div>{{ translations['campaign_Pwa_Steps_Step_2'] }}</div>
				</div>
				<div class="rin-pwa-dont-show-again" @click="dontShowAgain">
					{{ translations['campaign_Pwa_Decline'] }}
				</div>
			</template>
		</BaseModalWrapper>

		<BaseModalWrapper
			size="prompt-bottom"
			:show="showIosPwaPopup"
			@close="showIosPwaPopup = false & $emit('close-pwa-modal')"
			class="rin-ios-pwa-popup"
		>
			<template #body>
				<div class="rin-pwa-header">
					<img src="https://cdn.rankedin.com/images/static/rankedin_app_incon.svg?1" alt="Rankedin">
					<h3>{{ translations['campaign_Pwa_Title'] }}</h3>
				</div>
				<div class="rin-pwa-body">
					<p>
						{{ translations['campaign_Pwa_Title_Ios'] }}
					</p>
					<div>
						{{ translations['campaign_Pwa_Steps_Step_1_Ios'] }}
						<img src="https://cdn.rankedin.com/images/static/ios_share_icon.svg" alt="share">
					</div>
					<div>
						{{ translations['campaign_Pwa_Steps_Step_2_Ios'] }}
						<img src="https://cdn.rankedin.com/images/static/ios_add_to_home.svg" alt="add to home">
					</div>
				</div>
				<div class="rin-pwa-dont-show-again" @click="dontShowAgain">
					{{ translations['campaign_Pwa_Decline'] }}
				</div>
			</template>
		</BaseModalWrapper>

		<BaseModalWrapper
			size="prompt"
			:show="showAndroidChangeBrowser"
			@close="showAndroidChangeBrowser = false & $emit('close-pwa-modal')"
			class="rin-android-change-browser-popup"
		>
			<template #body>
				<h3>{{ translations['campaign_Pwa_Title'] }}</h3>
				<div class="rin-pwa-body">
					<div class="rin-pwa-description">
						<img src="https://cdn.rankedin.com/images/static/rankedin_app_incon.svg?1" alt="Rankedin">
						<div>
							<h4>Rankedin</h4>
							<h6>rankedin.app</h6>
						</div>
					</div>

					<p>{{ translations['campaign_Pwa_Steps_Title'] }}</p>
					<div>{{ translations['campaign_Pwa_Steps_Step_1'] }}<span> Chrome</span></div>
					<div>{{ translations['campaign_Pwa_Steps_Step_2'] }}</div>
				</div>
				<div class="rin-pwa-dont-show-again" @click="dontShowAgain">
					{{ translations['campaign_Pwa_Decline'] }}
				</div>
			</template>
		</BaseModalWrapper>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import BaseButton from '@/components/rin_new_design/components/base/BaseButton.vue';
import BaseModalWrapper from '@/components/rin_new_design/components/base/modals/BaseModalWrapper';

import Cookies from 'js-cookie';
import moment from 'moment';
import { detect } from 'detect-browser';

function _afterFiveNavigations(store) {
	return new Promise(function (resolve) {
		(function waitForFoo() {
			if (store.getters['ui/getNavigationsCount'] >= 5) return resolve();
			setTimeout(waitForFoo, 100);
		})();
	});
}
const pwaSettings = {
	name: 'PwaCampaign',
	async checkAudienceAsync(params) {
		if (!params.store.getters.layout.isEmbed && params.store.getters['ui/isEnabledPwaPopup']
            && (detect().os.match(/android/i) || detect().os.match(/ios/i))) {
			await _afterFiveNavigations(params.store);
			return Promise.resolve(true);
		}

		return Promise.resolve(false);
	},
};
export { pwaSettings };

export default {
	name: 'PwaCampaign',
	props: {},
	data() {
		return {
            userBrowser: detect().name,
			userPlatform: detect().os,

			showIosChangeBrowser: false,
			showIosPwaPopup: false,
			showAndroidChangeBrowser: false,
			showAndroidPwaPopup: false,
		};
	},
	components: {
		BaseButton,
		BaseModalWrapper,
	},
	mounted() {
		if (this.userPlatform?.match(/ios/i)) {
            if (this.isIOSSafari) {
				this.showIosPwaPopup = true;
			} else {
				this.showIosChangeBrowser = true;
			}
		} else if (this.userPlatform?.match(/android/i)) {
			if (this.userBrowser?.match(/chrome/i)) {
				this.showAndroidPwaPopup = true;
			} else {
				this.showAndroidChangeBrowser = true;
			}
		}
		else {
            this.showAndroidPwaPopup = true;
        }
	},
	computed: {
		...mapGetters(['translations', 'layout', 'nomenclatures']),
        ...mapGetters('ui', ['getPwaPromptRef']),
		isIOSSafari() {
            return this.userPlatform?.match(/ios/i) && (this.userBrowser?.match(/ios/i)?.index == 0 || this.userBrowser?.match(/safari/i));
        }
	},
	methods: {
		...mapMutations('ui', ['setPwaPromptRef']),
		async installPwa() {
			const prompt = this.getPwaPromptRef;
			if (prompt !== null) {
				prompt.prompt();
				const { outcome } = await prompt.userChoice;
				this.setPwaPromptRef(null);

				if (outcome === 'accepted') {
					this.$gtag.event('pwa_accept', { account: this.layout.player.rankedinId });
				} else if (outcome === 'dismissed') {
					this.$gtag.event('pwa_decline', { account: this.layout.player.rankedinId });
				}

				this.closeWhicheverOpen();
			}
		},
		dontShowAgain() {
			this.closeWhicheverOpen();

			if (this.layout.player.isAuthenticated) {
                this.$http.post(`${this.nomenclatures.ApiDomain}/campaign/UpdatePlayerCampaignAsync`, {
					Campaign: this.nomenclatures.CampaignEnum.PwaInstallAdvertUntilIgnored,
				});
			} else {
				let modalAdsCookie;
				try {
					modalAdsCookie = JSON.parse(Cookies.get('modal-ads'));
				} catch (error) {
					modalAdsCookie = {};
					modalAdsCookie._ads = [];
				}

				const cookieRecord = modalAdsCookie?._ads?.find(
					(el) => el._id === this.nomenclatures.CampaignEnum['PwaInstallAdvertUntilIgnored']
				);

				if (cookieRecord) {
					cookieRecord._lastAdDate = moment().utc().format('YYYY-MM-DD');
				} else {
					modalAdsCookie._ads.push({
						_id: this.nomenclatures.CampaignEnum['PwaInstallAdvertUntilIgnored'],
						_lastAdDate: moment().utc().format('YYYY-MM-DD'),
					});
				}
				Cookies.set('modal-ads', JSON.stringify(modalAdsCookie), { expires: 60, path: '/' });
			}
		},
		closeWhicheverOpen() {
			this.showIosChangeBrowser = false;
			this.showIosPwaPopup = false;
			this.showAndroidChangeBrowser = false;
			this.showAndroidPwaPopup = false;
		},
	},
};
</script>
