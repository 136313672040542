import axios from 'axios';
export default {
	namespaced: true,
	state: {
		isProPlayer: false,
		isPremiumEvent: false,
		selectedPremiumTournamentPlan: null,
		currentEventType: '',
		showFreeTrialModal: false,
		isFreeTrialStarted: false,
		isJoinedInPremiumEvent: false,
		shouldShowAds: false
	},
	mutations: {
		mutateIsProPlayer(state, payload) {
			state.isProPlayer = payload;
		},
		updateisPremiumEventState(state, payload) {
			state.isPremiumEvent = payload;
		},
		updateSelectedPremiumTournamentPlan(state, payload) {
			state.selectedPremiumTournamentPlan = payload;
		},
		updateCurrentEventType(state, payload) {
			state.currentEventType = payload;
		},
		updateShowFreeTrialModal(state, payload) {
			state.showFreeTrialModal = payload;
		},
		updateIsFreeTrialStarted(state, payload) {
			state.isFreeTrialStarted = payload;
		},
		mutateIsJoinedInPremiumEvent(state, payload) {
			state.isJoinedInPremiumEvent = payload;
		},
		updateShouldShowAds(state, payload) {
			state.shouldShowAds = payload;
        }
	},
	actions: {
		updateIsProPlayer(ctx, v) {
			ctx.commit('mutateIsProPlayer', v);
		},
		updateisPremiumEvent({ commit, rootState }, v) {
			const nomenclatures = rootState.nomenclatures.nomenclatures;
			if (nomenclatures.IsPremiumAllowed) {
				axios(`${nomenclatures.ApiDomain}/Monetization/GetIsPremiumTournamentAsync`, {
					params: { tournamentId: v },
				})
					.then((res) => {
						commit('updateisPremiumEventState', res.data);
					})
					.catch(() => {
						//i guess if theres whatever error it should still be false?
						commit('updateisPremiumEventState', false);
					});
			} else {
				commit('updateisPremiumEventState', false);
			}
		},
		updateIsJoinedInPremiumEvent(ctx, v) {
			ctx.commit('mutateIsJoinedInPremiumEvent', v);
		},
	},
	getters: {
		selectedPremiumTournamentPlan(state) {
			return state.selectedPremiumTournamentPlan;
		},
		isProPlayer(state) {
			return state.isProPlayer;
		},
		isPremiumEvent(state, getters) {
			return state.isPremiumEvent && !getters.isTournamentKillSwitchActive;
		},
		showRatings(state, getters) {
			if (getters.currentEventType === 'Tournament') {
				return getters.isProPlayer || getters.isPremiumEvent;
			} else {
				return getters.isProPlayer;
			}
		},
		//needed externally for case to hide all PRO indicators when killswitch is active
		isTournamentKillSwitchActive(state, getters, rootState) {
			return !rootState.nomenclatures.nomenclatures.IsPremiumAllowed;
		},
		currentEventType(state) {
			return state.currentEventType;
		},
		showFreeTrialModal(state) {
			return state.showFreeTrialModal;
		},
		isFreeTrialStarted(state) {
			return state.isFreeTrialStarted;
		},
		isJoinedInPremiumEvent(state) {
			return state.isJoinedInPremiumEvent;
		},
		shouldShowAds(state) {
			return state.shouldShowAds;
        }
	},
};
