import { ApplicationInsights } from '@microsoft/applicationinsights-web'


export default {
    install(Vue, options) {
        this.store = options.store;

        Vue.prototype.$log = this;
    },

    store: null,
    appInsights: null,
    isInitialized: false,

    nomenclatures() {
        return this.store.getters.nomenclatures;
    },

    init() {
        if (!this.nomenclatures().ApplicationInsightsConnectionString) return;
        this.appInsights = new ApplicationInsights({
            config: {
                connectionString: this.nomenclatures().ApplicationInsightsConnectionString
            }
        });
        this.appInsights.loadAppInsights();
        this.appInsights.trackPageView();
        console.log("ApplicationInsights loaded!");
        this.isInitialized = true;
    },

    debug(message) {
        if (this.isInitialized) this.appInsights.trackTrace({ message: message });
        console.log(message);
    },
    info(message) {
        if (this.isInitialized) this.appInsights.trackTrace({ message: message });
        console.log(message);
    },
    warn(message) {
        if (this.isInitialized) this.appInsights.trackTrace({ message: message });
        console.log(message);
    },
    error(message) {
        if (message instanceof Error)
            if (this.isInitialized) this.appInsights.trackException({ exception: message });
        else
            if (this.isInitialized) this.appInsights.trackException({ exception: new Error(message) });

        console.error(message);
    },
    log(message) {
        if (this.isInitialized) this.appInsights.trackTrace({ message: message });
        console.log(message);
    },

}
