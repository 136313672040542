<template>
	<div class="rin-alert" :class="`rin-alert-${type} with-background${hasBackground ? '' : '-disabled'}`">
		<slot />
	</div>
</template>
<script>
export default {
	name: 'BaseButton',
	props: {
		type: {
			type: String,
			validator: (v) => ['success', 'danger', 'info', 'white'].indexOf(v) > -1,
			required: true,
		},
		hasBackground: {
			type: Boolean,
			default: true,
		},
	},
};
</script>
