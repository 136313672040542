<template>
    <div class="hidden-print">
        <CreateEventModals />
        <EnterResultModal />
        <div v-if="showCookieAlert" class="cookie-alert alert alert-success alert-dismissible text-center fade in show" role="alert">
            <strong>Cookies:</strong> This site uses cookies to deliver its services. <a :href="`/${this.layout.language}/home/cookiepolicy`">Read more</a>
            <button @click="acceptCookies" type="button" class="btn btn-success" aria-label="Close">I Accept</button>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';

    import CreateEventModals from '@/components/shared/layout-create-event-modals'
    import EnterResultModal from '@/components/shared/layout-enter-match-results-bs4'
    import Cookies from 'js-cookie'

    export default {
        name: "layout-footer",
        components:
        {
            CreateEventModals,
            EnterResultModal
        },
        data() {
            return {             
                showCookieAlert: false,
                weUseCookies: "rankedin-first",
            }
        },
        computed: {
            ...mapGetters(['layout','translations', 'nomenclatures']),

            isIOSorAndroidUserDeviceRin() {
                return this.nomenclatures.UserDeviceRin == 'ios' ||
                    this.nomenclatures.UserDeviceRin == 'android';
            },
            yearNow() {
                return moment.utc().year();
            }
        },
        mounted() {
            if (!Cookies.get(this.weUseCookies)) {
                this.showCookieAlert = true;
            }          
        },
        methods: {
            acceptCookies: function () {
                Cookies.set(this.weUseCookies, true, { expires: 10000, path: '/' });
                this.showCookieAlert = false;
            }
        }
    }
</script>
<style scoped>
    .cookie-alert {
        z-index: 2147483647;
        border-radius: 0;
        position: fixed;
        bottom: 0;
        width: 100%;
        margin-bottom: 0
    }
</style>