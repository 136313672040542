<template>
    <div v-if="$windowSize.isMobile && shouldShowAd">
        <template v-if="isProd">
            <HideAdsBtn class="hide-ads" />
            <p class="advertisement-info">{{ translations['shared_Ads_Label'] }}</p>
            <ins class="adsbygoogle"
                 style="display:block"
                 data-ad-client="ca-pub-2431348456451065"
                 data-ad-slot="2827628646"
                 data-ad-format="auto"
                 data-full-width-responsive="true"></ins>
        </template>
        <template v-else>
            <HideAdsBtn class="hide-ads" />
            <p class="advertisement-info">{{ translations['shared_Ads_Label'] }}</p>
            <img style="width:100%; display: block; margin: auto" src="https://cdn.rankedin.com/images/static/dummy200x200.png" />
        </template>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import HideAdsBtn from '@/components/rin_new_design/components/GoogleAds/HideAdsButton.vue';

    export default {
        name: 'Box1',
        components: {
            HideAdsBtn
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),
            shouldShowAd() {

                const isProPlayer = this.$store.getters['monetization/isProPlayer'];
                if (isProPlayer) return false;

                const isProEvent = this.$store.getters['monetization/isPremiumEvent'];
                const currentEventType = this.$store.getters['monetization/currentEventType'];

                if (isProEvent && currentEventType === 'Tournament') return false;

                return true;
            },
            isProd() {
                return this.nomenclatures.Environment == 'prod';
            }
        },
        mounted() {
            if (this.isProd && this.$windowSize.isMobile && this.shouldShowAd) {
                setTimeout(() => {
                    (window.adsbygoogle = window.adsbygoogle || []).push({});
                }, 500);
            }
        }
    }
</script>
<style>
    .advertisement-info {
        margin-bottom: 2px;
        font-size: 12px;
        color: grey;
        text-align: right;
        margin-right: 10px;
    }

    .hide-ads {
        position: absolute;
    }
</style>