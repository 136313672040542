import axios from 'axios'
import _ from 'lodash';
import { MOMENT_DATE_FORMAT, MOMENT_DATETIME_FORMAT, DATETIMEPICKER_MALOT_FORMAT } from '@/app/constants';

export default {
    state: {
        nomenclatures: {},
        isLoaded: false
    },
    mutations: {
        updateNomenclatures(state, payload) {
            state.nomenclatures = payload;

            state.nomenclatures.DateFormat_Moment = MOMENT_DATE_FORMAT;
            state.nomenclatures.DateTimeFormat_Moment = MOMENT_DATETIME_FORMAT;
            state.nomenclatures.DateFormat_DatePickerBS4 = DATETIMEPICKER_MALOT_FORMAT;
            if (typeof (Cookies) != 'undefined') state.nomenclatures.UserDeviceRin = Cookies.get('user-device-rin');

            state.nomenclatures.SportBaseIconClass = {
                1: "rin-sports-squash",
                2: "rin-sports-badminton",
                3: "rin-sports-table-tennis",
                4: "rin-sports-tennis",
                5: "rin-sports-padel",
                6: "rin-sports-racquetball",
                7: "rin-sports-soccersquash",
                8: "rin-sports-fronton",
                9: "rin-sports-teqball",
                10: "rin-sports-crossminton",
                11: "rin-sports-pickleball",
                12: "rin-sports-roundnet",
                13: "rin-sports-bowls",
                14: "rin-sports-footballtennis",
            };
            state.nomenclatures.SportIconClass = _.map(state.nomenclatures.SportBaseIconClass, a => 'icon-' + a);

			
			state.nomenclatures.PlayerSubscriptionPeriods = {
				'Daily': 'Day',
				'Monthly': 'Month',
				'Yearly': 'Year',
				'Weekly': 'Week'
			}

            if (!_.isArray(state.nomenclatures.GendersReversed)) state.nomenclatures.GendersReversed = _.values(payload.GendersReversed).splice(0, _.values(payload.GendersReversed).length);
            state.nomenclatures.ProductTypes = Object.assign(_.invert(state.nomenclatures.ProductTypes), state.nomenclatures.ProductTypes);
            state.nomenclatures.PaymentProviders = Object.assign(_.invert(state.nomenclatures.PaymentProviders), state.nomenclatures.PaymentProviders);
            state.nomenclatures.PaymentMethods = Object.assign(_.invert(state.nomenclatures.PaymentMethods), state.nomenclatures.PaymentMethods);
            state.nomenclatures.BookmarkTypes = Object.assign(_.invert(state.nomenclatures.BookmarkTypes), state.nomenclatures.BookmarkTypes);
            state.nomenclatures.PaymentTypes = Object.assign(_.invert(state.nomenclatures.PaymentTypes), state.nomenclatures.PaymentTypes);
            state.nomenclatures.TeamMatchStates = Object.assign(_.invert(state.nomenclatures.TeamMatchStates), state.nomenclatures.TeamMatchStates);
			state.nomenclatures.PlayerSubscriptionPeriodTypesEnum = Object.assign(_.invert(state.nomenclatures.PlayerSubscriptionPeriodTypesEnum), state.nomenclatures.PlayerSubscriptionPeriodTypesEnum);

            state.isLoaded = true;
        },
        removeNomenclatureIdProp(state, payload) {
            _.forOwn(state.nomenclatures, function (value, key) {
                if (_.isObject(value) && !_.isArray(value)) {
                    state.nomenclatures[key] = _.pickBy(value, function (v, k) { return k !== '$id' });
                }
            });
        }
    },
    actions: {
        updateNomenclatures: ({ commit }, payload) => {
            commit("updateNomenclatures", payload);
        },
        async getNomenclatures({ state, commit }, payload) {
            return axios.get(payload)
                .then(response => {
                    commit("updateNomenclatures", response.data);
                    commit("removeNomenclatureIdProp");
                });            
        }
    },
    getters: {
        nomenclatures: state =>
            state.nomenclatures
    }
};